<template>
  <div class="el-dialog-div">
    <el-row>
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <div style="font-size: 20px; color: #000">变更前</div>
          <template v-for="(item, index) in oldList">
            <el-card style="margin-bottom: 10px" :key="index">
              <div class="title">{{ item.name }}</div>
              <el-descriptions :column="1" border>
                <el-descriptions-item label-class-name="labelClassName">
                  <template v-for="a in item.list">
                    <span :key="a.id">
                      <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        v-model="a.bootDisable"
                        disabled
                        style="margin-right: 10px">
                      </el-switch>
                      <span>{{ a.code | dict(limitType) }}</span>
                      <el-input v-model="a.amount" readonly class="bd_input"></el-input>
                    </span>
                  </template> </el-descriptions-item
              ></el-descriptions>
            </el-card>
          </template></div
      ></el-col>

      <el-col :span="12">
        <div class="grid-content bg-purple-light">
          <div style="font-size: 20px; color: rgb(245, 62, 62)">变更后</div>
          <template v-for="(item, index) in newList">
            <el-card style="margin-bottom: 10px" :key="index">
              <div class="title">{{ item.name }}</div>
              <el-descriptions :column="1" border>
                <el-descriptions-item label-class-name="labelClassName">
                  <template v-for="a in item.list">
                    <span :key="a.id">
                      <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        v-model="a.bootDisable"
                        disabled
                        style="margin-right: 10px">
                      </el-switch>
                      <span>{{ a.code | dict(limitType) }}</span>
                      <el-input
                        v-model="a.amount"
                        readonly
                        :class="
                          a.sign == 1 || a.Disable == 1 ? 'color bd_input' : 'bd_input'
                        "></el-input>
                    </span>
                  </template> </el-descriptions-item
              ></el-descriptions>
            </el-card>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'ChangeDetails',
  components: {},
  props: {
    limitType: {
      type: Array,
      default: function () {
        return []
      },
    },
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      newList: [],
      oldList: [],
    }
  },
  computed: {},
  watch: {
    list: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        newVal.forEach(v => {
          if (v.logType == 'OLD') {
            this.oldList = []
            const result = {}
            for (let i = 0; i < v.difRuleList.length; i++) {
              const item = v.difRuleList[i]
              item.amount = item.amount.toLocaleString()
              const name = item.name
              if (!result[name]) {
                result[name] = []
              }
              result[name].push(item)
            }
            for (const name in result) {
              this.oldList.push({ name: name, list: result[name] })
            }
          } else if (v.logType == 'CHANGE') {
            this.newList = []
            const result = {}
            for (let i = 0; i < v.difRuleList.length; i++) {
              const item = v.difRuleList[i]
              item.amount = item.amount.toLocaleString()
              const name = item.name
              if (!result[name]) {
                result[name] = []
              }
              result[name].push(item)
            }
            for (const name in result) {
              this.newList.push({ name: name, list: result[name] })
            }
            this.oldList.forEach(one => {
              one.list.forEach(two => {
                this.newList.forEach(three => {
                  three.list.forEach(four => {
                    if (two.code == four.code && two.name == four.name) {
                      if (two.bootDisable != four.bootDisable) {
                        four.Disable = 1
                      }
                    }
                  })
                })
              })
            })
          }
        })
      },
    },
  },
  created() {},
  beforeDestroy() {
    this.newList = []
    this.oldList = []
  },
  methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.el-dialog-div {
  height: 70vh;
  overflow: auto;
  .title {
    font-size: 20px;
    color: #000;
    font-weight: 700;
  }
  .color {
    background-color: #f5e9057d !important;
  }
  .bd_input {
    border: 1px solid #ccc;
    width: 90px;
    margin: 0px 20px 0px 10px;
    height: 40px;
    border-radius: 5%;
    text-align: center;
  }
  // 改变input框背景颜色
  /deep/.el-input__inner {
    background-color: transparent !important;
    border: 0 !important;
  }
  /* 利用穿透，设置input边框隐藏 */
  .bd_input >>> .el-input__inner {
    border: 0;
  }
  /deep/.labelClassName {
    width: 20px;
  }
}
</style>
